import { useState, useEffect } from 'react';
import { Plus, Edit, Loader2 } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Badge } from "@/components/ui/badge";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";

interface Platform {
  id: number;
  platform_name: string;
  is_enabled: boolean;
  auto_disabled: boolean;
  model_active: boolean;
}

interface Model {
  id: string;
  model_name: string;
  model_types: string[];
  is_active: boolean;
  created_at: string;
  updated_at: string;
  platforms: Platform[];
}

interface ModelFormData {
  model_name: string;
  model_types: string[];
  platform_ids: number[];
}

const initialFormData: ModelFormData = {
  model_name: '',
  model_types: [],
  platform_ids: []
};

const ModelManagement = () => {
  const { toast } = useToast();
  const [models, setModels] = useState<Model[]>([]);
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState<ModelFormData>(initialFormData);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState<string>('all');
  const [filterPlatform, setFilterPlatform] = useState<number>(0);
  const [selectedModels, setSelectedModels] = useState<string[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [modelToDelete, setModelToDelete] = useState<Model | null>(null);
  const [modelTypes, setModelTypes] = useState<string[]>([]);
  const [isAddingNewType, setIsAddingNewType] = useState(false);
  const [newModelType, setNewModelType] = useState('');

  useEffect(() => {
    fetchModels();
    fetchPlatforms();
    fetchModelTypes();
  }, []);

  const fetchModels = async () => {
    try {
      const response = await fetch('/api/models', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('获取模型数据失败');
      }
      const data = await response.json();
      
      // 使用 model_name 作为唯一标识进行聚合
      const modelMap = new Map<string, Model>();
      data.forEach((model: any) => {  // 临时使用 any 类型来处理后端数据
        const key = model.model_name;
        if (!modelMap.has(key)) {
          // 创建新的模型记录
          modelMap.set(key, {
            ...model,
            id: model.model_name,
            platforms: [model.platforms].flat(),  // 确保是数组
            model_types: [model.model_type]  // 将单个类型转换为数组
          });
        } else {
          // 如果模型已存在，合并平台信息和类型信息
          const existingModel = modelMap.get(key)!;
          // 合并平台
          const newPlatforms = [model.platforms].flat();
          newPlatforms.forEach(platform => {
            if (!existingModel.platforms.some(p => p.id === platform.id)) {
              existingModel.platforms.push(platform);
            }
          });
          // 合并类型
          if (!existingModel.model_types.includes(model.model_type)) {
            existingModel.model_types.push(model.model_type);
          }
        }
      });

      const uniqueModels = Array.from(modelMap.values());
      setModels(uniqueModels);
    } catch (err) {
      toast({
        title: "错误",
        description: err instanceof Error ? err.message : '获取模型数据失败',
        variant: "destructive"
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchPlatforms = async () => {
    try {
      const response = await fetch('/api/platforms', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('获取平台数据失败');
      }
      const data = await response.json();
      setPlatforms(data);
    } catch (err) {
      toast({
        title: "错误",
        description: err instanceof Error ? err.message : '获取平台数据失败',
        variant: "destructive"
      });
    }
  };

  const fetchModelTypes = async () => {
    try {
      const response = await fetch('/api/models/types', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('获取请求格式失败');
      }
      const data = await response.json();
      setModelTypes(data);
    } catch (err) {
      toast({
        title: "错误",
        description: err instanceof Error ? err.message : '获取请求格式失败',
        variant: "destructive"
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      const url = editingId 
        ? `/api/models/${encodeURIComponent(editingId)}`
        : '/api/models';
      
      const response = await fetch(url, {
        method: editingId ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || '保存失败');
      }

      await fetchModels();
      resetForm();
      toast({
        description: `模型${editingId ? '更新' : '创建'}成功`,
      });
    } catch (err) {
      toast({
        title: "错误",
        description: err instanceof Error ? err.message : '保存失败',
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setEditingId(null);
    setIsModalOpen(false);
    setIsAddingNewType(false);
  };

  const handleOpenModal = () => {
    resetForm();
    setIsModalOpen(true);
  };

  const handleEdit = (model: Model) => {
    setEditingId(model.model_name);
    setFormData({
      model_name: model.model_name,
      model_types: model.model_types,
      platform_ids: model.platforms.map(p => p.id)
    });
    setIsAddingNewType(false);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setFormData(initialFormData);
    setEditingId(null);
    setIsModalOpen(false);
    setIsAddingNewType(false);
  };

  const handleDelete = async (model: Model) => {
    try {
      const response = await fetch(`/api/models/${encodeURIComponent(model.model_name)}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        throw new Error('删除模型失败');
      }

      await fetchModels();
      toast({
        title: "成功",
        description: "模型已删除",
      });
    } catch (err) {
      toast({
        title: "错误",
        description: err instanceof Error ? err.message : '删除失败',
        variant: "destructive"
      });
    }
  };

  const handleToggleStatus = async (model: Model) => {
    try {
      const response = await fetch(`/api/models/${encodeURIComponent(model.model_name)}/toggle`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || '更新状态失败');
      }

      // 刷新模型列表
      await fetchModels();
    } catch (err) {
      toast({
        title: "错误",
        description: err instanceof Error ? err.message : '更新状态失败',
        variant: "destructive"
      });
    }
  };

  const filteredModels = models.filter(model => {
    const matchesSearch = model.model_name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesType = filterType === 'all' || model.model_types.includes(filterType);
    const matchesPlatform = filterPlatform === 0 || 
      model.platforms.some(p => p.id === filterPlatform);
    return matchesSearch && matchesType && matchesPlatform;
  });

  const handleBatchToggle = async (enable?: boolean) => {
    if (selectedModels.length === 0) return;
    
    try {
      const promises = selectedModels.map(modelName => 
        fetch(`/api/models/${encodeURIComponent(modelName)}/toggle`, {
          method: 'PATCH',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          },
          ...(enable !== undefined && {
            body: JSON.stringify({ is_active: enable })
          })
        })
      );

      await Promise.all(promises);
      await fetchModels();
      setSelectedModels([]);
    } catch (err) {
      toast({
        title: "错误",
        description: "批量操作失败",
        variant: "destructive"
      });
    }
  };

  const handleBatchDelete = async () => {
    if (selectedModels.length === 0) return;

    try {
      const promises = selectedModels.map(id => 
        fetch(`/api/models/${id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
      );

      await Promise.all(promises);
      await fetchModels();
      setSelectedModels([]);
      toast({
        title: "成功",
        description: "已删除选中的模型",
      });
    } catch (err) {
      toast({
        title: "错误",
        description: "批量删除失败",
        variant: "destructive"
      });
    }
  };

  const handleAddNewType = async () => {
    if (!newModelType || formData.model_types.includes(newModelType)) {
      return;
    }

    try {
      const response = await fetch('/api/models/types', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ model_type: newModelType })
      });

      if (!response.ok) {
        throw new Error('添加请求格式失败');
      }

      // 刷新请求格式列表
      await fetchModelTypes();
      
      // 更新表单数据
      setFormData({
        ...formData,
        model_types: [...formData.model_types, newModelType]
      });
      
      setNewModelType('');
      setIsAddingNewType(false);

      toast({
        description: "新请求格式添加成功",
      });
    } catch (err) {
      toast({
        title: "错误",
        description: err instanceof Error ? err.message : '添加请求格式失败',
        variant: "destructive"
      });
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      <div className="shrink-0 space-y-6 mb-6">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-semibold tracking-tight">模型管理</h1>
          <Button onClick={handleOpenModal}>
            <Plus className="mr-2 h-4 w-4" />
            添加模型
          </Button>
        </div>

        <div className="flex items-center gap-4">
          <div className="flex-1">
            <Input
              placeholder="搜索模型名称..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="max-w-sm"
              autoComplete="off"
            />
          </div>
          <Select value={filterType} onValueChange={setFilterType}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="选择请求格式" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">全部请求类型</SelectItem>
              {modelTypes.map((type) => (
                <SelectItem key={type} value={type}>
                  {type}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Select 
            value={filterPlatform.toString()} 
            onValueChange={(value) => setFilterPlatform(parseInt(value))}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="选择平台" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem key="platform-all" value="0">全部平台</SelectItem>
              {platforms.map((platform) => (
                <SelectItem 
                  key={`platform-${platform.id}`} 
                  value={platform.id.toString()}
                >
                  {platform.platform_name}
                  {!platform.is_enabled && ' (已禁用)'}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {selectedModels.length > 0 && (
          <div className="flex items-center gap-2">
            <span className="text-sm text-muted-foreground">
              已选择 {selectedModels.length} 个模型
            </span>
            <Button 
              variant="secondary" 
              size="sm"
              onClick={() => handleBatchToggle(true)}
            >
              批量启用
            </Button>
            <Button 
              variant="secondary" 
              size="sm"
              onClick={() => handleBatchToggle(false)}
            >
              批量禁用
            </Button>
            <Button 
              variant="destructive" 
              size="sm"
              onClick={handleBatchDelete}
            >
              批量删除
            </Button>
          </div>
        )}
      </div>

      <div className="flex-1 min-h-0 border rounded-md overflow-auto">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[50px]">
                <Checkbox
                  checked={
                    filteredModels.length > 0 && 
                    filteredModels.length === filteredModels.filter(model => 
                      selectedModels.includes(model.model_name)
                    ).length
                  }
                  onCheckedChange={(checked) => {
                    if (checked) {
                      // 全选当前过滤后的模型
                      const filteredIds = filteredModels.map(model => model.model_name);
                      setSelectedModels(filteredIds);
                    } else {
                      // 消全选
                      setSelectedModels([]);
                    }
                  }}
                />
              </TableHead>
              <TableHead>模型名称</TableHead>
              <TableHead>请求类型</TableHead>
              <TableHead>支持平台</TableHead>
              <TableHead>状态</TableHead>
              <TableHead className="w-[100px]">操作</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredModels.map((model) => (
              <TableRow key={model.model_name}>
                <TableCell>
                  <Checkbox
                    checked={selectedModels.includes(model.model_name)}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setSelectedModels([...selectedModels, model.model_name]);
                      } else {
                        setSelectedModels(selectedModels.filter(id => id !== model.model_name));
                      }
                    }}
                  />
                </TableCell>
                <TableCell>{model.model_name}</TableCell>
                <TableCell>
                  <div className="flex flex-wrap gap-1">
                    {model.model_types.map((type) => (
                      <Badge key={type} variant="secondary">
                        {type}
                      </Badge>
                    ))}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex flex-wrap gap-1">
                    {model.platforms.map((platform) => {
                      const isOffline = platform.auto_disabled;
                      const isDisabled = !platform.is_enabled || !platform.model_active;
                      let statusText = '';
                      let statusColor = '';

                      if (isOffline) {
                        statusText = '(离线)';
                        statusColor = 'text-red-500';
                      } else if (isDisabled) {
                        statusText = '(已禁用)';
                        statusColor = 'text-yellow-500';
                      }

                      return (
                        <Badge
                          key={`${model.model_name}-${platform.id}`}
                          variant={isOffline || isDisabled ? "outline" : "secondary"}
                          className={isOffline ? "border-red-200" : isDisabled ? "border-yellow-200" : ""}
                        >
                          {platform.platform_name}
                          {statusText && (
                            <span className={`ml-1 ${statusColor}`}>
                              {statusText}
                            </span>
                          )}
                        </Badge>
                      );
                    })}
                  </div>
                </TableCell>
                <TableCell>
                  <Badge variant={model.is_active ? "success" : "warning"}>
                    {model.is_active ? '已启用' : '已禁用'}
                  </Badge>
                </TableCell>
                <TableCell>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="icon">
                        <Edit className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuItem onClick={() => handleEdit(model)}>
                        编辑
                      </DropdownMenuItem>
                      <DropdownMenuItem 
                        onClick={() => handleToggleStatus(model)}
                        className={model.is_active ? "text-yellow-600" : "text-green-600"}
                      >
                        {model.is_active ? '禁用' : '启用'}
                      </DropdownMenuItem>
                      <DropdownMenuItem 
                        onClick={() => {
                          setModelToDelete(model);
                          setDeleteDialogOpen(true);
                        }}
                        className="text-red-600"
                      >
                        删除
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <Dialog open={isModalOpen} onOpenChange={handleModalClose}>
        <DialogContent className="sm:max-w-[600px] max-h-[90vh] overflow-y-auto scrollbar-hide">
          <DialogHeader className="space-y-3">
            <DialogTitle className="text-2xl">
              {editingId ? '编辑' : '添加'}模型
            </DialogTitle>
            <DialogDescription className="text-base">
              请填写以下信息来{editingId ? '编辑' : '添加'}模型。所有带 <span className="text-destructive">*</span> 的字段为必填项。
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={handleSubmit} className="space-y-8 py-4">
            <div className="grid gap-6">
              <div className="space-y-4">
                <label className="text-sm font-medium leading-none">
                  支持平台 <span className="text-destructive">*</span>
                </label>
                <div className="grid grid-cols-2 gap-4">
                  {platforms.map((platform) => (
                    <div
                      key={`platform-checkbox-${platform.id}`}
                      className="flex items-center space-x-2 rounded-md border p-4 hover:bg-muted/50"
                    >
                      <Checkbox
                        id={`platform-${platform.id}`}
                        checked={formData.platform_ids.includes(platform.id)}
                        onCheckedChange={(checked) => {
                          const newPlatformIds = checked
                            ? [...formData.platform_ids, platform.id]
                            : formData.platform_ids.filter(id => id !== platform.id);
                          setFormData({ ...formData, platform_ids: newPlatformIds });
                        }}
                      />
                      <label
                        htmlFor={`platform-${platform.id}`}
                        className="flex flex-1 items-center justify-between cursor-pointer"
                      >
                        <span className="text-sm font-medium leading-none">
                          {platform.platform_name}
                        </span>
                        {!platform.is_enabled && (
                          <Badge variant="outline" className="ml-2">
                            已禁用
                          </Badge>
                        )}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium leading-none">
                  模型名称 <span className="text-destructive">*</span>
                </label>
                <Input
                  value={formData.model_name}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^a-zA-Z0-9_\-\.\/]/g, '');
                    setFormData({ ...formData, model_name: value });
                  }}
                  placeholder="请输入模型名称(仅支持英文、数字和常用符号)"
                  autoComplete="off"
                  required
                />
                <p className="text-sm text-muted-foreground">
                  仅支持英文、数字、下划线(_)、中划线(-)、点(.)、斜杠(/)等符号
                </p>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium leading-none">
                  请求格式 <span className="text-destructive">*</span>
                </label>
                <div className="space-y-4">
                  <div className="grid grid-cols-2 gap-4">
                    {modelTypes.map((type) => (
                      <div
                        key={`type-${type}`}
                        className="flex items-center space-x-2 rounded-md border p-4 hover:bg-muted/50"
                      >
                        <Checkbox
                          id={`type-${type}`}
                          checked={formData.model_types.includes(type)}
                          onCheckedChange={(checked) => {
                            const newTypes = checked
                              ? [...formData.model_types, type]
                              : formData.model_types.filter(t => t !== type);
                            setFormData({ ...formData, model_types: newTypes });
                          }}
                        />
                        <label
                          htmlFor={`type-${type}`}
                          className="flex flex-1 items-center justify-between cursor-pointer"
                        >
                          <span className="text-sm font-medium leading-none">
                            {type}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>

                  <Button
                    type="button"
                    variant="outline"
                    className="w-full"
                    onClick={() => setIsAddingNewType(true)}
                  >
                    + 添加新的请求格式
                  </Button>

                  {isAddingNewType && (
                    <div className="space-y-2">
                      <Input
                        placeholder="请输入新的请求格式(仅支持小写字母和数字)"
                        value={newModelType}
                        onChange={(e) => {
                          const value = e.target.value.toLowerCase().replace(/[^a-z0-9]/g, '');
                          setNewModelType(value);
                        }}
                        autoComplete="off"
                      />
                      <div className="flex gap-2">
                        <Button
                          type="button"
                          variant="secondary"
                          size="sm"
                          className="flex-1"
                          onClick={handleAddNewType}
                          disabled={!newModelType || formData.model_types.includes(newModelType)}
                        >
                          确认添加
                        </Button>
                        <Button
                          type="button"
                          variant="ghost"
                          size="sm"
                          className="flex-1"
                          onClick={() => {
                            setNewModelType('');
                            setIsAddingNewType(false);
                          }}
                        >
                          取消
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <DialogFooter>
              <Button
                type="button"
                variant="outline"
                onClick={() => setIsModalOpen(false)}
                disabled={isSubmitting}
              >
                取消
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    保存中...
                  </>
                ) : (
                  '保存'
                )}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>确认删除</AlertDialogTitle>
            <AlertDialogDescription>
              您确定要删除模型 "{modelToDelete?.model_name}" 吗？此操作无法撤销。
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>取</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                if (modelToDelete) {
                  handleDelete(modelToDelete);
                }
                setDeleteDialogOpen(false);
              }}
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            >
              删除
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default ModelManagement; 