import { useState, useEffect } from 'react';
import { Plus, Copy, Loader2, Edit } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import { Checkbox } from "@/components/ui/checkbox";
import { useToast } from "@/components/ui/use-toast";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogCancel,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";

interface ApiKey {
  id: number;
  api_key: string;
  description: string;
  is_active: boolean;
  created_at: string;
  updated_at: string;
}

const formatApiKey = (key: string) => {
  if (!key) return '';
  const prefix = key.slice(0, 7);
  const suffix = key.slice(-6);
  return `${prefix}${'*'.repeat(10)}${suffix}`;
};

const initialFormData = {
  description: '',
};

const ApiKeyManagement = () => {
  const { toast } = useToast();
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
  const [apiKeyToDelete, setApiKeyToDelete] = useState<ApiKey | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    fetchApiKeys();
  }, []);

  const fetchApiKeys = async () => {
    try {
      const response = await fetch('/api/api-keys', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('获取API密钥数据失败');
      }
      const data = await response.json();
      setApiKeys(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : '获取API密钥数据失败');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      const response = await fetch('/api/api-keys', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || '创建API密钥失败');
      }

      await fetchApiKeys();
      setIsModalOpen(false);  // 关闭模态框
      setFormData(initialFormData);  // 重置表单
      toast({
        description: "API密钥创建成功"
      });
    } catch (err) {
      toast({
        title: "错误",
        description: err instanceof Error ? err.message : '创建失败',
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (apiKey: ApiKey) => {
    try {
      const response = await fetch(`/api/api-keys/${apiKey.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        throw new Error('删除API密钥失败');
      }

      await fetchApiKeys();
      toast({
        description: "API密钥已删除",
      });
    } catch (err) {
      toast({
        title: "错误",
        description: err instanceof Error ? err.message : '删除失败',
        variant: "destructive"
      });
    }
  };

  const handleToggleStatus = async (apiKey: ApiKey) => {
    try {
      const response = await fetch(`/api/api-keys/${apiKey.id}/toggle`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        throw new Error('更新状态失败');
      }

      await fetchApiKeys();
    } catch (err) {
      setError(err instanceof Error ? err.message : '更新状态失败');
    }
  };

  const handleBatchToggle = async (enable: boolean) => {
    if (selectedKeys.length === 0) return;

    try {
      const promises = selectedKeys.map(id =>
        fetch(`/api/api-keys/${id}/toggle`, {
          method: 'PATCH',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({ is_enabled: enable })
        })
      );

      await Promise.all(promises);
      await fetchApiKeys();
      setSelectedKeys([]);
      toast({
        description: `已${enable ? '启用' : '禁用'}选中的API密钥`,
      });
    } catch (err) {
      setError(err instanceof Error ? err.message : '批量操作失败');
    }
  };

  const handleBatchDelete = async () => {
    if (selectedKeys.length === 0) return;

    setApiKeyToDelete(null); // 批量删除时设置为 null
    setDeleteDialogOpen(true);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-64 text-red-500">
        {error}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold tracking-tight">API密钥管理</h1>
        <Button onClick={() => setIsModalOpen(true)}>
          <Plus className="mr-2 h-4 w-4" />
          添加密钥
        </Button>
      </div>

      <div className="flex items-center gap-4">
        <div className="flex-1">
          <Input
            placeholder="搜索密钥名称..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="max-w-sm"
            autoComplete="off"
          />
        </div>
      </div>

      {selectedKeys.length > 0 && (
        <div className="flex items-center gap-2">
          <span className="text-sm text-muted-foreground">
            已选择 {selectedKeys.length} 个密钥
          </span>
          <Button 
            variant="secondary" 
            size="sm"
            onClick={() => handleBatchToggle(true)}
          >
            批量启用
          </Button>
          <Button 
            variant="secondary" 
            size="sm"
            onClick={() => handleBatchToggle(false)}
          >
            批量禁用
          </Button>
          <Button 
            variant="destructive" 
            size="sm"
            onClick={handleBatchDelete}
          >
            批量删除
          </Button>
        </div>
      )}

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[50px]">
                <Checkbox
                  checked={
                    apiKeys.length > 0 &&
                    selectedKeys.length === apiKeys.length
                  }
                  onCheckedChange={(checked) => {
                    if (checked) {
                      setSelectedKeys(apiKeys.map(key => key.id));
                    } else {
                      setSelectedKeys([]);
                    }
                  }}
                />
              </TableHead>
              <TableHead>描述</TableHead>
              <TableHead>API密钥</TableHead>
              <TableHead>状态</TableHead>
              <TableHead>创建时间</TableHead>
              <TableHead className="w-[100px]">操作</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {apiKeys.map((apiKey) => (
              <TableRow key={apiKey.id}>
                <TableCell>
                  <Checkbox
                    checked={selectedKeys.includes(apiKey.id)}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setSelectedKeys([...selectedKeys, apiKey.id]);
                      } else {
                        setSelectedKeys(selectedKeys.filter(id => id !== apiKey.id));
                      }
                    }}
                  />
                </TableCell>
                <TableCell>{apiKey.description}</TableCell>
                <TableCell>
                  <div className="flex items-center space-x-2">
                    <code className="px-2 py-1 bg-muted rounded text-sm font-mono tracking-wider">
                      {formatApiKey(apiKey.api_key)}
                    </code>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => {
                        navigator.clipboard.writeText(apiKey.api_key);
                        toast({
                          description: "API密钥已复制到剪贴板",
                        });
                      }}
                    >
                      <Copy className="h-4 w-4" />
                    </Button>
                  </div>
                </TableCell>
                <TableCell>
                  <Badge variant={apiKey.is_active ? "success" : "warning"}>
                    {apiKey.is_active ? '已启用' : '已禁用'}
                  </Badge>
                </TableCell>
                <TableCell>
                  {new Date(apiKey.created_at).toLocaleString()}
                </TableCell>
                <TableCell>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="icon">
                        <Edit className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuItem 
                        onClick={() => handleToggleStatus(apiKey)}
                        className={apiKey.is_active ? "text-yellow-600" : "text-green-600"}
                      >
                        {apiKey.is_active ? '禁用' : '启用'}
                      </DropdownMenuItem>
                      <DropdownMenuItem 
                        onClick={() => {
                          setApiKeyToDelete(apiKey);
                          setDeleteDialogOpen(true);
                        }}
                        className="text-red-600"
                      >
                        删除
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader className="space-y-3">
            <DialogTitle className="text-2xl">添加API密钥</DialogTitle>
            <DialogDescription className="text-base">
              请填写以下信息来添加API密钥。所有带 <span className="text-destructive">*</span> 的字段为必填项。
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-4">
              <div className="space-y-2">
                <label className="text-sm font-medium leading-none">
                  描述 <span className="text-destructive">*</span>
                </label>
                <Input
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  placeholder="请输入API密钥的用途描述"
                  autoComplete="off"
                  required
                />
                <p className="text-sm text-muted-foreground">
                  用于标识此API密钥的用途，例如：测试环境、生产环境等
                </p>
              </div>
            </div>

            <DialogFooter>
              <Button
                type="button"
                variant="outline"
                onClick={() => setIsModalOpen(false)}
                disabled={isSubmitting}
              >
                取消
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    保存中...
                  </>
                ) : (
                  '保存'
                )}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>确认删除</AlertDialogTitle>
            <AlertDialogDescription>
              {apiKeyToDelete 
                ? `确定要删除密钥 "${apiKeyToDelete.description}" 吗？`
                : `确定要删除选中的 ${selectedKeys.length} 个密钥吗？`}
              删除后无法恢复，相关的应用将无法继续使用此密钥。
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>取消</AlertDialogCancel>
            <AlertDialogAction
              onClick={async () => {
                if (apiKeyToDelete) {
                  await handleDelete(apiKeyToDelete);
                } else {
                  // 批量删除
                  try {
                    const promises = selectedKeys.map(id =>
                      fetch(`/api/api-keys/${id}`, {
                        method: 'DELETE',
                        headers: {
                          'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                      })
                    );

                    await Promise.all(promises);
                    await fetchApiKeys();
                    setSelectedKeys([]);
                    toast({
                      description: "已删除选中的API密钥",
                    });
                  } catch (err) {
                    toast({
                      title: "错误",
                      description: "批量删除失败",
                      variant: "destructive"
                    });
                  }
                }
                setDeleteDialogOpen(false);
              }}
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            >
              删除
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default ApiKeyManagement; 