import { 
  createBrowserRouter, 
  RouterProvider,
  createRoutesFromElements,
  Route,
  Navigate
} from "react-router-dom";
import { AuthProvider } from '@/contexts/AuthContext';
import Layout from '@/components/Layout';
import LoginPage from '@/pages/Login';
import PlatformManagement from '@/pages/PlatformManagement';
import ModelManagement from '@/pages/ModelManagement';
import ApiKeyManagement from '@/pages/ApiKeyManagement';
import PrivateRoute from '@/components/PrivateRoute';
import { Toaster } from "@/components/ui/toaster";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Navigate to="/admin/platforms" replace />} />
      <Route path="/login" element={<LoginPage />} />
      <Route 
        path="/admin" 
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        <Route index element={<Navigate to="platforms" replace />} />
        <Route path="platforms" element={<PlatformManagement />} />
        <Route path="models" element={<ModelManagement />} />
        <Route path="api-keys" element={<ApiKeyManagement />} />
      </Route>
      <Route path="*" element={<Navigate to="/admin/platforms" replace />} />
    </>
  )
);

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
      <Toaster />
    </AuthProvider>
  );
}

export default App; 