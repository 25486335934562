import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';
import { LayoutDashboard, Box, Key, LogOut } from 'lucide-react';
import { Button } from "@/components/ui/button"
import { Separator } from "@/components/ui/separator"
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useState } from 'react';
import { cn } from '@/lib/utils';

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const menuItems = [
    { path: '/admin/platforms', label: '平台管理', icon: LayoutDashboard },
    { path: '/admin/models', label: '模型管理', icon: Box },
    { path: '/admin/api-keys', label: 'API密钥', icon: Key },
  ];

  return (
    <div className="min-h-screen bg-background">
      <div className="flex h-screen">
        {/* 侧边栏 */}
        <aside className={cn(
          "fixed left-0 top-0 h-full bg-card border-r z-30",
          "flex flex-col transition-all duration-300 ease-in-out",
          isSidebarOpen ? "w-64" : "w-14",
          "hover:shadow-lg"
        )}>
          {/* Logo 和标题区域 */}
          <div className={cn(
            "flex items-center h-16 px-4",
            isSidebarOpen ? "justify-between" : "justify-center"
          )}>
            {isSidebarOpen && <h1 className="text-xl font-semibold">管理平台</h1>}
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="p-0 h-8 w-8 hover:bg-muted"
            >
              {isSidebarOpen ? <ChevronLeft className="h-4 w-4" /> : <ChevronRight className="h-4 w-4" />}
            </Button>
          </div>
          <Separator />
          
          {/* 导航菜单区域 */}
          <nav className="flex-1 overflow-y-auto py-3">
            <div className="space-y-1 px-3">
              {menuItems.map((item) => {
                const Icon = item.icon;
                return (
                  <Button
                    key={item.path}
                    variant={location.pathname === item.path ? "secondary" : "ghost"}
                    className={cn(
                      "w-full",
                      isSidebarOpen ? "justify-start px-3" : "justify-center p-0 h-10 w-10",
                      location.pathname === item.path && "bg-muted"
                    )}
                    onClick={() => navigate(item.path)}
                  >
                    <Icon className={cn(
                      "shrink-0",
                      isSidebarOpen ? "mr-2 h-4 w-4" : "h-5 w-5"
                    )} />
                    {isSidebarOpen && <span>{item.label}</span>}
                  </Button>
                );
              })}
            </div>
          </nav>
          
          {/* 退出登录按钮区域 */}
          <div className="p-3 border-t">
            <Button 
              variant="ghost" 
              className={cn(
                "w-full text-muted-foreground",
                isSidebarOpen ? "justify-start px-3" : "justify-center p-0 h-10 w-10"
              )}
              onClick={logout}
            >
              <LogOut className={cn(
                "shrink-0",
                isSidebarOpen ? "mr-2 h-4 w-4" : "h-5 w-5"
              )} />
              {isSidebarOpen && <span>退出登录</span>}
            </Button>
          </div>
        </aside>

        {/* 主内容区域 */}
        <main className={cn(
          "flex-1 flex flex-col",
          "transition-all duration-300 ease-in-out",
          isSidebarOpen ? "ml-64" : "ml-14"
        )}>
          <div className="flex-1 p-6 overflow-auto">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default Layout; 