import { useState, useEffect } from 'react';
import { Plus, Edit, Loader2 } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useToast } from "@/components/ui/use-toast";

interface Platform {
  id: number;
  platform_name: string;
  base_url: string;
  platform_url: string | null;
  api_key: string;
  path_prefix: string;
  is_official: boolean;
  is_enabled: boolean;
  weight: number;
  default_model_type: string;
  last_check_result: string | null;
  auto_disabled: boolean;
  created_at: string;
  updated_at: string;
}

const PlatformManagement = () => {
  const { toast } = useToast();
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingId, setEditingId] = useState<number | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPlatforms, setSelectedPlatforms] = useState<number[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [platformToDelete, setPlatformToDelete] = useState<Platform | null>(null);
  const [existingModelTypes, setExistingModelTypes] = useState<string[]>([]);
  const [isCustomType, setIsCustomType] = useState(false);

  const [formData, setFormData] = useState({
    platform_name: '',
    base_url: '',
    platform_url: '',
    api_key: '',
    path_prefix: '',
    is_official: false,
    weight: 100,
    default_model_type: ''
  });

  useEffect(() => {
    fetchPlatforms();
    fetchExistingModelTypes();
  }, []);

  const fetchPlatforms = async () => {
    try {
      const response = await fetch('/api/platforms', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) throw new Error('获取平台数据失败');
      const data = await response.json();
      console.log('Platform data:', data);
      setPlatforms(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : '获取平台数据失败');
    } finally {
      setLoading(false);
    }
  };

  const fetchExistingModelTypes = async () => {
    try {
      const response = await fetch('/api/platforms/model-types', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) throw new Error('获取请求格式失败');
      const data = await response.json();
      setExistingModelTypes(data);
    } catch (err) {
      console.error('获取请求格式失败:', err);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const url = editingId ? `/api/platforms/${editingId}` : '/api/platforms';
      const method = editingId ? 'PUT' : 'POST';
      
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || '保存失败');
      }
      
      await fetchPlatforms();
      setIsModalOpen(false);
      setFormData({
        platform_name: '',
        base_url: '',
        platform_url: '',
        api_key: '',
        path_prefix: '',
        is_official: false,
        weight: 100,
        default_model_type: ''
      });
      setEditingId(null);
      
      toast({
        title: "成功",
        description: editingId ? "平台更新成功" : "平台创建成功",
        variant: "default"
      });
    } catch (err) {
      toast({
        title: "错误",
        description: err instanceof Error ? err.message : '操作失败',
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEdit = (platform: Platform) => {
    setEditingId(platform.id);
    setFormData({
      platform_name: platform.platform_name,
      base_url: platform.base_url,
      platform_url: platform.platform_url || '',
      api_key: platform.api_key,
      path_prefix: platform.path_prefix,
      is_official: platform.is_official,
      weight: platform.weight,
      default_model_type: platform.default_model_type
    });
    setIsModalOpen(true);
  };

  const handleDelete = (platform: Platform) => {
    setPlatformToDelete(platform);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async (platform: Platform) => {
    try {
      const response = await fetch(`/api/platforms/${platform.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        if (response.status === 500 && response.headers.get('content-type')?.includes('application/json')) {
          const data = await response.json();
          if (data.sqlMessage?.includes('foreign key constraint fails')) {
            throw new Error('有模型在使用当前平台，请修改当前平台的"请求格式"属性为"Empty platform"后操作。');
          }
        }
        throw new Error('删除失败');
      }

      await fetchPlatforms();
      toast({
        title: "成功",
        description: "平台已成功删除",
        variant: "default"
      });
    } catch (err) {
      console.error('删除失败:', err);
      toast({
        title: "错误",
        description: err instanceof Error ? err.message : '删除失败',
        variant: "destructive"
      });
    } finally {
      setDeleteDialogOpen(false);
    }
  };

  const handleToggleStatus = async (platform: Platform) => {
    try {
      const response = await fetch(`/api/platforms/${platform.id}/toggle`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || '更新状态失败');
      }

      // 刷新平台列表
      await fetchPlatforms();
    } catch (err) {
      console.error('更新状态失败:', err);
      toast({
        title: "错误",
        description: err instanceof Error ? err.message : '更新状态失败',
        variant: "destructive"
      });
    }
  };

  const handleBatchToggle = async (enable: boolean) => {
    if (selectedPlatforms.length === 0) return;

    try {
      const promises = selectedPlatforms.map(id =>
        fetch(`/api/platforms/${id}/toggle`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({ is_enabled: enable })
        })
      );

      await Promise.all(promises);
      await fetchPlatforms();
      setSelectedPlatforms([]);
    } catch (err) {
      setError(err instanceof Error ? err.message : '批量操作失败');
    }
  };

  const handleBatchDelete = async () => {
    if (selectedPlatforms.length === 0) return;

    try {
      const promises = selectedPlatforms.map(id =>
        fetch(`/api/platforms/${id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
      );

      await Promise.all(promises);
      await fetchPlatforms();
      setSelectedPlatforms([]);
      
      toast({
        title: "成功",
        description: "选中的平台已成功删除",
        variant: "default"
      });
    } catch (err) {
      console.error('批量删除失败:', err);
      toast({
        title: "错误",
        description: err instanceof Error ? err.message : '批量删除失败',
        variant: "destructive"
      });
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setFormData({
      platform_name: '',
      base_url: '',
      platform_url: '',
      api_key: '',
      path_prefix: '',
      is_official: false,
      weight: 100,
      default_model_type: ''
    });
    setEditingId(null);
  };

  // 修改验证函数，添加长度限制
  const isValidModelType = (value: string) => {
    return /^[a-zA-Z0-9-]+$/.test(value) && value.length <= 20; // 限制最大长度为20个字符
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-center">
          <Loader2 className="w-8 h-8 animate-spin text-blue-500 mx-auto" />
          <p className="mt-2 text-sm text-muted-foreground">加载中...</p>
        </div>
      </div>
    );
  }

  if (error) {
    toast({
      title: "错误",
      description: error,
      variant: "destructive"
    });
    setError(null);
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold tracking-tight">平台管理</h1>
        <Button onClick={() => setIsModalOpen(true)}>
          <Plus className="mr-2 h-4 w-4" />
          添加平台
        </Button>
      </div>

      <div className="flex items-center gap-4">
        <div className="flex-1">
          <Input
            placeholder="搜索平台名称..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="max-w-sm"
            autoComplete="off"
          />
        </div>
      </div>

      {selectedPlatforms.length > 0 && (
        <div className="flex items-center gap-2">
          <span className="text-sm text-muted-foreground">
            已选择 {selectedPlatforms.length} 个平台
          </span>
          <Button 
            variant="secondary" 
            size="sm"
            onClick={() => handleBatchToggle(true)}
          >
            批量启用
          </Button>
          <Button 
            variant="secondary" 
            size="sm"
            onClick={() => handleBatchToggle(false)}
          >
            批量禁用
          </Button>
          <Button 
            variant="destructive" 
            size="sm"
            onClick={handleBatchDelete}
          >
            批量删除
          </Button>
        </div>
      )}

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[50px]">
                <Checkbox
                  checked={selectedPlatforms.length === platforms.length && platforms.length > 0}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      setSelectedPlatforms(platforms.map(p => p.id));
                    } else {
                      setSelectedPlatforms([]);
                    }
                  }}
                />
              </TableHead>
              <TableHead>平台名称</TableHead>
              <TableHead>基础URL</TableHead>
              <TableHead>请求类型</TableHead>
              <TableHead>默认请求格式</TableHead>
              <TableHead>状态</TableHead>
              <TableHead>权重</TableHead>
              <TableHead className="w-[100px]">操作</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {platforms.map((platform) => (
              <TableRow key={platform.id}>
                <TableCell>
                  <Checkbox
                    checked={selectedPlatforms.includes(platform.id)}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setSelectedPlatforms([...selectedPlatforms, platform.id]);
                      } else {
                        setSelectedPlatforms(selectedPlatforms.filter(id => id !== platform.id));
                      }
                    }}
                  />
                </TableCell>
                <TableCell>{platform.platform_name}</TableCell>
                <TableCell>{platform.base_url}</TableCell>
                <TableCell>
                  <Badge variant={platform.is_official ? "default" : "secondary"}>
                    {platform.is_official ? '官方' : '第三方'}
                  </Badge>
                </TableCell>
                <TableCell>
                  <Badge variant="outline">
                    {platform.default_model_type}
                  </Badge>
                </TableCell>
                <TableCell>
                  <Badge variant={platform.is_enabled ? "success" : "warning"}>
                    {platform.is_enabled ? '已启用' : '已禁用'}
                  </Badge>
                </TableCell>
                <TableCell>{platform.weight}</TableCell>
                <TableCell>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="icon">
                        <Edit className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuItem onClick={() => handleEdit(platform)}>
                        编辑
                      </DropdownMenuItem>
                      <DropdownMenuItem 
                        onClick={() => handleToggleStatus(platform)}
                        className={platform.is_enabled ? "text-yellow-600" : "text-green-600"}
                      >
                        {platform.is_enabled ? '禁用' : '启用'}
                      </DropdownMenuItem>
                      <DropdownMenuItem 
                        onClick={() => handleDelete(platform)}
                        className="text-red-600"
                      >
                        删除
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <Dialog open={isModalOpen} onOpenChange={handleModalClose}>
        <DialogContent className="sm:max-w-[600px] max-h-[90vh] overflow-y-auto scrollbar-hide">
          <DialogHeader className="space-y-3">
            <DialogTitle className="text-2xl">
              {editingId ? '编辑' : '添加'}平台
            </DialogTitle>
            <DialogDescription className="text-base">
              请填写以下信息来{editingId ? '编辑' : '添加'}平台。所有带 <span className="text-destructive">*</span> 的字段为必填项。
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={handleSubmit} className="space-y-8 py-4">
            <div className="grid gap-6">
              <div className="space-y-2">
                <label className="text-sm font-medium leading-none">
                  平台名称 <span className="text-destructive">*</span>
                </label>
                <Input
                  value={formData.platform_name}
                  onChange={(e) => setFormData({ ...formData, platform_name: e.target.value })}
                  placeholder="请输入平台名称"
                  autoComplete="off"
                  required
                />
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium leading-none">
                  基础URL <span className="text-destructive">*</span>
                </label>
                <Input
                  value={formData.base_url}
                  onChange={(e) => setFormData({ ...formData, base_url: e.target.value })}
                  placeholder="例如: https://api.openai.com"
                  autoComplete="off"
                  required
                />
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium leading-none">
                  平台URL
                </label>
                <Input
                  value={formData.platform_url}
                  onChange={(e) => setFormData({ ...formData, platform_url: e.target.value })}
                  placeholder="例如: https://platform.openai.com"
                  autoComplete="off"
                />
                <p className="text-sm text-muted-foreground">
                  可选，用于跳转到平台官网
                </p>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium leading-none">
                  API密钥 <span className="text-destructive">*</span>
                </label>
                <Input
                  type="password"
                  value={formData.api_key}
                  onChange={(e) => setFormData({ ...formData, api_key: e.target.value })}
                  placeholder="请输入API密钥"
                  required
                />
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium leading-none">
                  路径前缀
                </label>
                <Input
                  value={formData.path_prefix}
                  onChange={(e) => setFormData({ ...formData, path_prefix: e.target.value })}
                  placeholder="/v1"
                  autoComplete="off"
                />
                <p className="text-sm text-muted-foreground">
                  注意：不要在后面增加 /
                </p>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium leading-none">
                  权重
                </label>
                <Input
                  type="number"
                  value={formData.weight}
                  onChange={(e) => setFormData({ ...formData, weight: parseInt(e.target.value) })}
                  min="0"
                  max="100"
                  placeholder="0-100"
                  autoComplete="off"
                />
                <p className="text-sm text-muted-foreground">
                  权重范围：0-100，默认：100。权重越高，优先级越高。
                </p>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium leading-none">
                  默认请求格式 <span className="text-destructive">*</span>
                </label>
                <div className="space-y-2">
                  <select
                    className="w-full rounded-md border border-input bg-background px-3 h-9"
                    value={isCustomType ? "custom" : formData.default_model_type}
                    onChange={(e) => {
                      if (e.target.value === "custom") {
                        setIsCustomType(true);
                        setFormData({ ...formData, default_model_type: '' });
                      } else {
                        setIsCustomType(false);
                        setFormData({ ...formData, default_model_type: e.target.value });
                      }
                    }}
                    required
                  >
                    <option value="">请选择请求格式</option>
                    {existingModelTypes.map((type) => (
                      <option key={type} value={type}>{type}</option>
                    ))}
                    <option value="custom">+ 添加新类型</option>
                  </select>

                  {isCustomType && (
                    <Input
                      value={formData.default_model_type}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === '' || isValidModelType(value)) {
                          setFormData({ ...formData, default_model_type: value });
                        }
                      }}
                      placeholder="请输入新的请求格式（仅支持英文和数字，最大20字符）"
                      required
                      pattern="[a-zA-Z0-9-]{1,20}"
                      title="仅支持英文字母、数字和连字符，最大长度20个字符"
                      maxLength={20}  // 添加最大长度限制
                    />
                  )}
                </div>
                <p className="text-sm text-muted-foreground">
                  选择或输入平台的默认请求格式
                </p>
              </div>

              <div className="flex items-center space-x-2">
                <Checkbox
                  id="is_official"
                  checked={formData.is_official}
                  onCheckedChange={(checked) => 
                    setFormData({ ...formData, is_official: checked as boolean })
                  }
                />
                <label
                  htmlFor="is_official"
                  className="text-sm font-medium leading-none cursor-pointer"
                >
                  官方平台
                </label>
              </div>
            </div>

            <DialogFooter>
              <Button
                type="button"
                variant="outline"
                onClick={() => setIsModalOpen(false)}
                disabled={isSubmitting}
              >
                取消
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    保存中...
                  </>
                ) : (
                  '保存'
                )}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>确认删除</AlertDialogTitle>
            <AlertDialogDescription>
              您确定要删除平台 "{platformToDelete?.platform_name}" 吗？此操作无法撤销，相关的API密钥和模型配置也将被删除。
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>取消</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                if (platformToDelete) {
                  confirmDelete(platformToDelete);
                }
                setDeleteDialogOpen(false);
              }}
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            >
              删除
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default PlatformManagement;